
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { getMedia } from '@/core/mc-admin-helpers/assets'
const _ = require('lodash')

export default defineComponent({
  name: 'kt-activity-drawer',
  components: {

  },
  setup () {
    const store = useStore()
    const loading = ref(false)
    const aiPoweredSearchKeywords = ref<string>('')
    const searchResponse = ref<string>('')
    const latestMessages = computed(() => store.getters.getLatestMessages)
    const avatarPath = ref('') as any
    const imageUri = computed(() => avatarPath.value)

    const addNewLine = () => {
      aiPoweredSearchKeywords.value = `${aiPoweredSearchKeywords.value}\n`
    }
    const messageContent = ref<HTMLDivElement | null>(null)
    const lottieOptions = {
      minimizable: false,
      playerSize: 'standard',
      backgroundColor: '#fff',
      backgroundStyle: 'color',
      theme: {
        controlsView: 'standard',
        active: 'light',
        light: {
          color: '#3D4852',
          backgroundColor: '#fff',
          opacity: '0.7'
        },
        dark: {
          color: '#fff',
          backgroundColor: '#202020',
          opacity: '0.7'
        }
      }
    }
    const receivedAiMessageStyle = {
      borderRadius: '6px',
      background: '#E6F6FF',
      color: '#191C30'
    }
    const generalMessageStyle = {
      margin: '1rem 1rem 1rem 0.7rem',
      boxSizing: 'border-box',
      padding: '0.6rem 1rem',
      borderRadius: '6px',
      background: '#33393C',
      color: '#ffffff',
      width: 'fit-content',
      maxWidth: '280px',
      boxShadow: '0 0 2rem rgb(0 0 0 / 8%), 0rem 1rem 1rem -1rem rgb(0 0 0 / 10%)'
    }
    const resetConversation = () => {
      store.dispatch(Actions.API_AI_RESET_CONVERSATION).then((result) => {
        // searchResponse.value = result.payload.choices[0].text.replace(/^\s+/, '').replace(/^\s+/, '')
        // const aiMessage = { } as any
        // aiMessage.content = searchResponse.value
        // aiMessage.generatedByAi = true
        // store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, aiMessage)
        // div.scrollIntoView()
        // loading.value = false
      })
    }
    const submit = () => {
      if (_.isEmpty(aiPoweredSearchKeywords.value)) {
        return
      }
      searchResponse.value = ''
      loading.value = true
      const userMessage = { } as any
      userMessage.content = aiPoweredSearchKeywords.value
      userMessage.generatedByAi = false
      store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, userMessage)
      const keywords = aiPoweredSearchKeywords.value
      aiPoweredSearchKeywords.value = ''
      const div = messageContent.value!
      div.scrollIntoView()
      store.dispatch(Actions.API_AI_SEARCH_BY_KEYWORDS, keywords).then((result) => {
        searchResponse.value = result.payload.choices[0].message.content.replace(/^\s+/, '').replace(/^\s+/, '')
        const aiMessage = { } as any
        aiMessage.content = searchResponse.value
        aiMessage.generatedByAi = true
        store.commit(Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES, aiMessage)
        div.scrollIntoView()
        loading.value = false
      })
    }
    const currentAccount = ref(computed(() => store.getters.currentAccount))

    onMounted(() => {
      const payloadAvatar = {} as any

      if (currentAccount.value) {
        payloadAvatar.accountUuid = currentAccount.value.accountUuid
        payloadAvatar.avatarPath = currentAccount.value.profileAvatarPath
        store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
          if (response.payload.image !== null) {
            avatarPath.value = response.payload.image
          } else {
            avatarPath.value = getMedia('/icons/admin-default-icon.png')
          }
        })
      }

      watch(() => currentAccount.value, (updated, oldAccount) => {
        if (!updated) {
          return
        }
        payloadAvatar.accountUuid = updated.accountUuid
        payloadAvatar.avatarPath = updated.profileAvatarPath
        store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
          if (response.payload.image !== null) {
            avatarPath.value = response.payload.image
          } else {
            avatarPath.value = getMedia('/icons/admin-default-icon.png')
          }
        })
        store.dispatch(Actions.API_AI_GET_LATEST_MESSAGES).then((result) => {
          console.log('results', result)
        })
      })
    })
    return {
      loading,
      searchResponse,
      aiPoweredSearchKeywords,
      latestMessages,
      generalMessageStyle,
      receivedAiMessageStyle,
      addNewLine,
      submit,
      resetConversation,
      messageContent,
      lottieOptions,
      imageUri,
      getMedia
    }
  }
})
