<template>
  <div>
    <el-button
        :loading="isLoading"
        type="primary"
        style="background-color: green;"
        @click="handleUploadClick"
    >
      <span v-if="!isLoading">Import</span>
      <span v-else>Uploading...</span>
    </el-button>
    <input
        ref="fileInput"
        type="file"
        accept=".zip"
        style="display: none;"
        @change="handleFileChange"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { ElMessage } from 'element-plus'

const store = useStore()
const isLoading = ref(false)
const fileInput = ref(null)

const handleUploadClick = () => {
  fileInput.value.click()
}

const handleFileChange = async (event) => {
  const file = event.target.files[0]
  if (file && file.type === 'application/zip') {
    isLoading.value = true
    // const formData = new FormData()
    // formData.append('file', file)
    const data = new FormData()
    data.append('zip_file', event.target.files[0])

    const config = {
      headers: { 'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2) },
      onUploadProgress: (progressEvent) => {
        // updateProgressPayload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        // store.commit(Mutations.UPDATE_FILE_UPLOAD_PROGRESS, updateProgressPayload)
      }
    }
    const payload = {
      data: data,
      config: config
    }

    try {
      await store.dispatch(Actions.API_IMPORT_TEMPLATE, payload).then((response) => {
        ElMessage.success('File uploaded successfully')
        window.location.href = '/builder/' + response.payload.activityTemplateUuid
      })
    } catch (error) {
      ElMessage.error('Failed to upload file')
    } finally {
      isLoading.value = false
      fileInput.value.value = null
    }
  } else {
    ElMessage.error('Please upload a valid ZIP file')
  }
}
</script>

<style scoped>
/* You can add custom styles here if needed */
</style>
