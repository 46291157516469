
import { defineComponent, inject } from 'vue'
import ImportTemplateButton from '@/views/ImportTemplateButton.vue'
// import KTSearch from '@/layout/header/partials/Search.vue'

export default defineComponent({
  name: 'topbar',
  props: {
    actionButton: Object
  },
  components: {
    ImportTemplateButton
    // KTSearch
  },
  setup () {
    const emitter = inject('emitter') as any
    const handleActionClick = (actionButton) => {
      if (actionButton.emits) {
        const emits = { action: actionButton.emits.actionName, value: {} } as any
        if (actionButton.emits.actionValue) {
          emits.value = actionButton.emits.actionValue
        }
        emitter.emit(emits.action, emits.value)
      }
    }
    return {
      handleActionClick
    }
  }
})
